import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Product.css";

const Product = ({ name, image, price }) => {
  return (
<div className="col-xl-3">
<div className="product-card">
<Link to="/productDetails">
<img src="https://retailminded.com/wp-content/uploads/2016/03/EN_GreenOlive-1.jpg" className="card-img-top" alt={name} width='100%' height='100%'/>
<div className="card-body">
 <h5 className="card-title">{name}</h5>
 <p className="card-text">${price}</p>
</div>
</Link>
</div>
</div>
  );
};

export default Product;
