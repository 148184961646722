import React from "react";
import "./Shop.css";
import { Link, useNavigate } from "react-router-dom";

const Shop = ({ name, image, description }) => {
  return (

    <div className="col-xl-3">
      <div className="shop-card">
  <Link to="/shopDetails">
    <img src="https://content.jdmagicbox.com/comp/gaya/j3/9999px631.x631.170927005819.d8j3/catalogue/big-shope-wazirganj-gaya-gents-readymade-garment-retailers-x783o.jpg" className="card-img-top" alt={name} width='100%' height='100%'/>
     <div className="card-body">
       <h5 className="card-title">{name}</h5>
       <p className="card-text">{description}</p>
     </div>
  </Link>
     
   </div>
    </div>
  );
};

export default Shop;
