import React from "react";
import "./SearchResultPage.css";

function SearchResultPage() {
  return (
    <>
    <div className="cartpage">
      <div className="lsbar">
          <div className="lsbdata">
              <h3>Search Result Page</h3>
              <p>Contents of search result page will come here.....</p>
          </div>
      </div>
    </div>
    </>
  );
}

export default SearchResultPage;
