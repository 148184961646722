export const countryOptions = [
  { value: 1, label: "Andorra" },
  { value: 2, label: "United Arab Emirates" },
  { value: 3, label: "Afghanistan" },
  { value: 4, label: "Antigua and Barbuda" },
  { value: 5, label: "Anguilla" },
  { value: 6, label: "Albania" },
  { value: 7, label: "Armenia" },
  { value: 8, label: "Angola" },
  { value: 9, label: "Antarctica" },
  { value: 10, label: "Argentina" },
  { value: 11, label: "American Samoa" },
  { value: 12, label: "Austria" },
  { value: 13, label: "Australia" },
  { value: 14, label: "Aruba" },
  { value: 15, label: "Åland Islands" },
  { value: 16, label: "Azerbaijan" },
  { value: 17, label: "Bosnia and Herzegovina" },
  { value: 18, label: "Barbados" },
  { value: 19, label: "Bangladesh" },
  { value: 20, label: "Belgium" },
  { value: 21, label: "Burkina Faso" },
  { value: 22, label: "Bulgaria" },
  { value: 23, label: "Bahrain" },
  { value: 24, label: "Burundi" },
  { value: 25, label: "Benin" },
  { value: 26, label: "Saint Barthélemy" },
  { value: 27, label: "Bermuda" },
  { value: 28, label: "Brunei Darussalam" },
  { value: 29, label: "Bolivia" },
  { value: 30, label: "Bonaire, Sint Eustatius and Saba" },
  { value: 31, label: "Brazil" },
  { value: 32, label: "Bahamas" },
  { value: 33, label: "Bhutan" },
  { value: 34, label: "Bouvet Island" },
  { value: 35, label: "Botswana" },
  { value: 36, label: "Belarus" },
  { value: 37, label: "Belize" },
  { value: 38, label: "Canada" },
  { value: 39, label: "Cocos [Keeling] Islands" },
  { value: 40, label: "Democratic Republic of the Congo" },
  { value: 41, label: "Central African Republic" },
  { value: 42, label: "Republic of the Congo" },
  { value: 43, label: "Switzerland" },
  { value: 44, label: "Ivory Coast" },
  { value: 45, label: "Cook Islands" },
  { value: 46, label: "Chile" },
  { value: 47, label: "Cameroon" },
  { value: 48, label: "China" },
  { value: 49, label: "Colombia" },
  { value: 50, label: "Costa Rica" },
  { value: 51, label: "Cuba" },
  { value: 52, label: "Cape Verde" },
  { value: 53, label: "Curaçao" },
  { value: 54, label: "Christmas Island" },
  { value: 55, label: "Cyprus" },
  { value: 56, label: "Czech Republic" },
  { value: 57, label: "Germany" },
  { value: 58, label: "Djibouti" },
  { value: 59, label: "Denmark" },
  { value: 60, label: "Dominica" },
  { value: 61, label: "Dominican Republic" },
  { value: 62, label: "Algeria" },
  { value: 63, label: "Ecuador" },
  { value: 64, label: "Estonia" },
  { value: 65, label: "Egypt" },
  { value: 66, label: "Western Sahara" },
  { value: 67, label: "Eritrea" },
  { value: 68, label: "Spain" },
  { value: 69, label: "Ethiopia" },
  { value: 70, label: "Finland" },
  { value: 71, label: "Fiji" },
  { value: 72, label: "Falkland Islands" },
  { value: 73, label: "Micronesia" },
  { value: 74, label: "Faroe Islands" },
  { value: 75, label: "France" },
  { value: 76, label: "Gabon" },
  { value: 77, label: "United Kingdom of Great Britain and Northern Ireland" },
  { value: 78, label: "Grenada" },
  { value: 79, label: "Georgia" },
  { value: 80, label: "French Guiana" },
  { value: 81, label: "Guernsey" },
  { value: 82, label: "Ghana" },
  { value: 83, label: "Gibraltar" },
  { value: 84, label: "Greenland" },
  { value: 85, label: "Gambia" },
  { value: 86, label: "Guinea" },
  { value: 87, label: "Guadeloupe" },
  { value: 88, label: "Equatorial Guinea" },
  { value: 89, label: "Greece" },
  { value: 90, label: "South Georgia and the South Sandwich Islands" },
  { value: 91, label: "Guatemala" },
  { value: 92, label: "Guam" },
  { value: 93, label: "Guinea-Bissau" },
  { value: 94, label: "Guyana" },
  { value: 95, label: "Hong Kong" },
  { value: 96, label: "Heard Island and McDonald Islands" },
  { value: 97, label: "Honduras" },
  { value: 98, label: "Croatia" },
  { value: 99, label: "Haiti" },
  { value: 100, label: "Hungary" },
  { value: 101, label: "Indonesia" },
  { value: 102, label: "Ireland" },
  { value: 103, label: "Isle of Man" },
  { value: 104, label: "India" },
  { value: 105, label: "British Indian Ocean Territory" },
  { value: 106, label: "Iraq" },
  { value: 107, label: "Iran" },
  { value: 108, label: "Iceland" },
  { value: 109, label: "Italy" },
  { value: 110, label: "Jersey" },
  { value: 111, label: "Jamaica" },
  { value: 112, label: "Jordan" },
  { value: 113, label: "Japan" },
  { value: 114, label: "Kenya" },
  { value: 115, label: "Kyrgyzstan" },
  { value: 116, label: "Cambodia" },
  { value: 117, label: "Kiribati" },
  { value: 118, label: "Comoros" },
  { value: 119, label: "Saint Kitts and Nevis" },
  { value: 120, label: "North Korea" },
  { value: 121, label: "South Korea" },
  { value: 122, label: "Kuwait" },
  { value: 123, label: "Cayman Islands" },
  { value: 124, label: "Kazakhstan" },
  { value: 125, label: "Laos" },
  { value: 126, label: "Lebanon" },
  { value: 127, label: "Saint Lucia" },
  { value: 128, label: "Liechtenstein" },
  { value: 129, label: "Sri Lanka" },
  { value: 130, label: "Liberia" },
  { value: 131, label: "Lesotho" },
  { value: 132, label: "Lithuania" },
  { value: 133, label: "Luxembourg" },
  { value: 134, label: "Latvia" },
  { value: 135, label: "Libya" },
  { value: 136, label: "Morocco" },
  { value: 137, label: "Monaco" },
  { value: 138, label: "Moldova" },
  { value: 139, label: "Montenegro" },
  { value: 140, label: "Saint Martin" },
  { value: 141, label: "Madagascar" },
  { value: 142, label: "Marshall Islands" },
  { value: 143, label: "North Macedonia" },
  { value: 144, label: "Mali" },
  { value: 145, label: "Myanmar [Burma]" },
  { value: 146, label: "Mongolia" },
  { value: 147, label: "Macao" },
  { value: 148, label: "Northern Mariana Islands" },
  { value: 149, label: "Martinique" },
  { value: 150, label: "Mauritania" },
  { value: 151, label: "Montserrat" },
  { value: 152, label: "Malta" },
  { value: 153, label: "Mauritius" },
  { value: 154, label: "Maldives" },
  { value: 155, label: "Malawi" },
  { value: 156, label: "Mexico" },
  { value: 157, label: "Malaysia" },
  { value: 158, label: "Mozambique" },
  { value: 159, label: "Namibia" },
  { value: 160, label: "New Caledonia" },
  { value: 161, label: "Niger" },
  { value: 162, label: "Norfolk Island" },
  { value: 163, label: "Nigeria" },
  { value: 164, label: "Nicaragua" },
  { value: 165, label: "Netherlands" },
  { value: 166, label: "Norway" },
  { value: 167, label: "Nepal" },
  { value: 168, label: "Nauru" },
  { value: 169, label: "Niue" },
  { value: 170, label: "New Zealand" },
  { value: 171, label: "Oman" },
  { value: 172, label: "Panama" },
  { value: 173, label: "Peru" },
  { value: 174, label: "French Polynesia" },
  { value: 175, label: "Papua New Guinea" },
  { value: 176, label: "Philippines" },
  { value: 177, label: "Pakistan" },
  { value: 178, label: "Poland" },
  { value: 179, label: "Saint Pierre and Miquelon" },
  { value: 180, label: "Pitcairn Islands" },
  { value: 181, label: "Puerto Rico" },
  { value: 182, label: "Palestine" },
  { value: 183, label: "Portugal" },
  { value: 184, label: "Palau" },
  { value: 185, label: "Paraguay" },
  { value: 186, label: "Qatar" },
  { value: 187, label: "Réunion" },
  { value: 188, label: "Romania" },
  { value: 189, label: "Serbia" },
  { value: 190, label: "Russia" },
  { value: 191, label: "Rwanda" },
  { value: 192, label: "Saudi Arabia" },
  { value: 193, label: "Solomon Islands" },
  { value: 194, label: "Seychelles" },
  { value: 195, label: "Sudan" },
  { value: 196, label: "Sweden" },
  { value: 197, label: "Singapore" },
  { value: 198, label: "Saint Helena" },
  { value: 199, label: "Slovenia" },
  { value: 200, label: "Svalbard and Jan Mayen" },
  { value: 201, label: "Slovakia" },
  { value: 202, label: "Sierra Leone" },
  { value: 203, label: "San Marino" },
  { value: 204, label: "Senegal" },
  { value: 205, label: "Somalia" },
  { value: 206, label: "Suriname" },
  { value: 207, label: "South Sudan" },
  { value: 208, label: "São Tomé and Príncipe" },
  { value: 209, label: "El Salvador" },
  { value: 210, label: "Sint Maarten" },
  { value: 211, label: "Syria" },
  { value: 212, label: "Eswatini" },
  { value: 213, label: "Turks and Caicos Islands" },
  { value: 214, label: "Chad" },
  { value: 215, label: "French Southern Territories" },
  { value: 216, label: "Togo" },
  { value: 217, label: "Thailand" },
  { value: 218, label: "Tajikistan" },
  { value: 219, label: "Tokelau" },
  { value: 220, label: "East Timor" },
  { value: 221, label: "Turkmenistan" },
  { value: 222, label: "Tunisia" },
  { value: 223, label: "Tonga" },
  { value: 224, label: "Turkey" },
  { value: 225, label: "Trinidad and Tobago" },
  { value: 226, label: "Tuvalu" },
  { value: 227, label: "Taiwan" },
  { value: 228, label: "Tanzania" },
  { value: 229, label: "Ukraine" },
  { value: 230, label: "Uganda" },
  { value: 231, label: "U.S. Minor Outlying Islands" },
  { value: 232, label: "United States" },
  { value: 233, label: "Uruguay" },
  { value: 234, label: "Uzbekistan" },
  { value: 235, label: "Vatican City" },
  { value: 236, label: "Saint Vincent and the Grenadines" },
  { value: 237, label: "Venezuela" },
  { value: 238, label: "British Virgin Islands" },
  { value: 239, label: "U.S. Virgin Islands" },
  { value: 240, label: "Vietnam" },
  { value: 241, label: "Vanuatu" },
  { value: 242, label: "Wallis and Futuna" },
  { value: 243, label: "Samoa" },
  { value: 244, label: "Kosovo" },
  { value: 245, label: "Yemen" },
  { value: 246, label: "Mayotte" },
  { value: 247, label: "South Africa" },
  { value: 248, label: "Zambia" },
  { value: 249, label: "Zimbabwe" },
];

export const cityOptions = [
  { value: 25112, label: "Karachi" },
  { value: 25113, label: "Lahore" },
  { value: 25114, label: "Faisalabad" },
  { value: 25115, label: "Rawalpindi" },
  { value: 25116, label: "Gujranwala" },
  { value: 25117, label: "Peshawar" },
  { value: 25118, label: "Multan" },
  { value: 25119, label: "Saidu Shar" },
  { value: 25120, label: "Hyderabad" },
  { value: 25121, label: "Islamabad" },
  { value: 25122, label: "Quetta" },
  { value: 25123, label: "Bahawalpur" },
  { value: 25124, label: "Sargodha" },
  { value: 25125, label: "Sialkot Ci" },
  { value: 25126, label: "Sukkur" },
  { value: 25127, label: "Larkana" },
  { value: 25128, label: "Chiniot" },
  { value: 25129, label: "Shekhupura" },
  { value: 25130, label: "Jhang City" },
  { value: 25131, label: "Dera Ghazi" },
  { value: 25132, label: "Gujrat" },
  { value: 25133, label: "Rahimyar K" },
  { value: 25134, label: "Kasur" },
  { value: 25135, label: "Mardan" },
  { value: 25136, label: "Nawabshah" },
  { value: 25137, label: "Sahiwal" },
  { value: 25138, label: "Mirpur Kha" },
  { value: 25139, label: "Okara" },
  { value: 25140, label: "Mandi Bure" },
  { value: 25141, label: "Jacobabad" },
  { value: 25142, label: "Saddiqabad" },
  { value: 25143, label: "Kohat" },
  { value: 25144, label: "Muridke" },
  { value: 25145, label: "Muzaffarga" },
  { value: 25146, label: "Khanpur" },
  { value: 25147, label: "Gojra" },
  { value: 25148, label: "Mandi Baha" },
  { value: 25149, label: "Abbottabad" },
  { value: 25150, label: "Turbat" },
  { value: 25151, label: "Dadu" },
  { value: 25152, label: "Khairpur M" },
  { value: 25153, label: "Bahawalnag" },
  { value: 25154, label: "Khuzdar" },
  { value: 25155, label: "Pakpattan" },
  { value: 25156, label: "Zafarwal" },
  { value: 25157, label: "Tando Alla" },
  { value: 25158, label: "Jaranwala" },
  { value: 25159, label: "Ahmadpur E" },
  { value: 25160, label: "Vihari" },
  { value: 25161, label: "New Mirpur" },
  { value: 25162, label: "Kamalia" },
  { value: 25163, label: "Kot Addu" },
  { value: 25164, label: "Nowshera" },
  { value: 25165, label: "Swabi" },
  { value: 25166, label: "Khushab" },
  { value: 25167, label: "Dera Ismai" },
  { value: 25168, label: "Chaman" },
  { value: 25169, label: "Charsadda" },
  { value: 25170, label: "Kandhkot" },
  { value: 25171, label: "Chishtian" },
  { value: 25172, label: "Hasilpur" },
  { value: 25173, label: "Attock Khu" },
  { value: 25174, label: "Kambar" },
  { value: 25175, label: "Arifwala" },
  { value: 25176, label: "Muzaffarab" },
  { value: 25177, label: "Mianwali" },
  { value: 25178, label: "Jalalpur J" },
  { value: 25179, label: "Bhakkar" },
  { value: 25180, label: "Zhob" },
  { value: 25181, label: "Dipalpur" },
  { value: 25182, label: "Kharian" },
  { value: 25183, label: "Mian Chann" },
  { value: 25184, label: "Bhalwal" },
  { value: 25185, label: "Jamshoro" },
  { value: 25186, label: "Pattoki" },
  { value: 25187, label: "Harunabad" },
  { value: 25188, label: "Kahror Pak" },
  { value: 25189, label: "Toba Tek S" },
  { value: 25190, label: "Samundri" },
  { value: 25191, label: "Shakargarh" },
  { value: 25192, label: "Sambrial" },
  { value: 25193, label: "Shujaabad" },
  { value: 25194, label: "Hujra Shah" },
  { value: 25195, label: "Kabirwala" },
  { value: 25196, label: "Mansehra" },
  { value: 25197, label: "Lala Musa" },
  { value: 25198, label: "Chunian" },
  { value: 25199, label: "Nankana Sa" },
  { value: 25200, label: "Bannu" },
  { value: 25201, label: "Pasrur" },
  { value: 25202, label: "Timargara" },
  { value: 25203, label: "Parachinar" },
  { value: 25204, label: "Chenab Nag" },
  { value: 25205, label: "Abdul Haki" },
  { value: 25206, label: "Gwadar" },
  { value: 25207, label: "Hassan Abd" },
  { value: 25208, label: "Tank" },
  { value: 25209, label: "Hangu" },
  { value: 25210, label: "Risalpur C" },
  { value: 25211, label: "Karak" },
  { value: 25212, label: "Kundian" },
  { value: 25213, label: "Umarkot" },
  { value: 25214, label: "Chitral" },
  { value: 25215, label: "Dainyor" },
  { value: 25216, label: "Kulachi" },
  { value: 25217, label: "Kalat" },
  { value: 25218, label: "Kot" },
  { value: 25219, label: "Murree" },
  { value: 25220, label: "Mithi" },
  { value: 25221, label: "Mian Sahib" },
  { value: 25222, label: "Gilgit" },
  { value: 25223, label: "Gilwala" },
];

export const businessCategories = [
  { value: "", label: "Please Select Business Category", description: "" },
  {
    value: 1,
    label: "Pharmacy / Grocery store",
    description: "Your one-stop shop for health and everyday essentials.",
  },
  {
    value: 2,
    label: "Electrical store",
    description: "Find a wide range of electrical products and accessories.",
  },
  {
    value: 3,
    label: "E-commerce Apparel store",
    description: "Shop for trendy apparel online.",
  },
  {
    value: 4,
    label: "Antique shop",
    description: "Discover unique and timeless antiques.",
  },
  {
    value: 5,
    label: "Art supply store",
    description: "Get your creative juices flowing with art supplies.",
  },
  {
    value: 6,
    label: "Bakery store",
    description: "Freshly baked goods for your delight.",
  },
  {
    value: 7,
    label: "Beauty & body care products store",
    description: "Find top beauty and body care products.",
  },
  {
    value: 8,
    label: "Bookstore",
    description: "Explore a world of knowledge through books.",
  },
  {
    value: 9,
    label: "Butcher store",
    description: "Quality meats for your culinary adventures.",
  },
  {
    value: 10,
    label: "Candy store",
    description: "Satisfy your sweet tooth with a variety of candies.",
  },
  {
    value: 11,
    label: "Car accessories",
    description: "Enhance your vehicle with car accessories.",
  },
  {
    value: 12,
    label: "Car store",
    description: "Get your dream car and accessories here.",
  },
  {
    value: 13,
    label: "Cleaning supplies store",
    description: "Keep your space clean and organized.",
  },
  {
    value: 14,
    label: "Clothes shop",
    description: "Upgrade your wardrobe with fashionable clothes.",
  },
  {
    value: 15,
    label: "Coffee shop",
    description: "Enjoy a cup of freshly brewed coffee.",
  },
  {
    value: 16,
    label: "Convenience store",
    description: "Convenient shopping for everyday items.",
  },
  {
    value: 17,
    label: "Cosmetics & make up store",
    description: "Discover a range of cosmetics and makeup products.",
  },
  {
    value: 18,
    label: "Department store",
    description: "Shop for a wide variety of products under one roof.",
  },
  {
    value: 19,
    label: "Disabled accessories store",
    description: "Accessibility solutions and aids.",
  },
  {
    value: 20,
    label: "Discount store",
    description: "Great deals and discounts on various products.",
  },
  {
    value: 21,
    label: "E-commerce store",
    description: "Shop online for a wide range of products.",
  },
  {
    value: 22,
    label: "Electrical Industrial store",
    description: "Industrial-grade electrical equipment and supplies.",
  },
  {
    value: 23,
    label: "Electronics Store",
    description: "The latest electronic gadgets and devices.",
  },
  {
    value: 24,
    label: "Fishing shop",
    description: "Everything you need for a fishing adventure.",
  },
  {
    value: 25,
    label: "Florist",
    description: "Fresh and beautiful floral arrangements.",
  },
  {
    value: 26,
    label: "Furniture store",
    description: "Quality furniture for your home or office.",
  },
  {
    value: 27,
    label: "Gaming Store",
    description: "Gaming consoles, accessories, and more.",
  },
  {
    value: 28,
    label: "Garden store",
    description: "Gardening tools and supplies for green thumbs.",
  },
  {
    value: 29,
    label: "Gift shop",
    description: "Unique gifts for special occasions.",
  },
  {
    value: 30,
    label: "Greengrocer",
    description: "Fresh and locally sourced fruits and vegetables.",
  },
  {
    value: 31,
    label: "Grocery store",
    description: "A wide range of groceries and food items.",
  },
  {
    value: 32,
    label: "Handbags store",
    description: "Stylish handbags for fashion-conscious shoppers.",
  },
  {
    value: 33,
    label: "Hardware store",
    description: "Hardware and DIY supplies for projects.",
  },
  {
    value: 34,
    label: "Herbal store",
    description: "Natural herbal remedies and products.",
  },
  {
    value: 35,
    label: "Home appliances & household goods store",
    description: "Appliances and household essentials.",
  },
  {
    value: 36,
    label: "Home decor store",
    description: "Decorate your space with trendy home decor.",
  },
  {
    value: 37,
    label: "Hunting shop",
    description: "Hunting gear and accessories.",
  },
  {
    value: 38,
    label: "Hypermarket",
    description: "A massive selection of products in one store.",
  },
  {
    value: 39,
    label: "Jewelry store",
    description: "Elegant jewelry pieces and accessories.",
  },
  {
    value: 40,
    label: "Kiosk",
    description: "Convenient and quick shopping at a kiosk.",
  },
  {
    value: 41,
    label: "Music shop",
    description: "Instruments, sheet music, and more.",
  },
  {
    value: 42,
    label: "Organic food store",
    description: "Organic and natural food products.",
  },
  {
    value: 43,
    label: "Pet store",
    description: "Supplies for your beloved pets.",
  },
  {
    value: 44,
    label: "Pharmacy / Drug store",
    description: "Medications and health essentials.",
  },
  {
    value: 45,
    label: "Regional foods store",
    description: "Explore regional and ethnic foods.",
  },
  {
    value: 46,
    label: "Retail outlet",
    description: "Discounted items and special deals.",
  },
  {
    value: 47,
    label: "Scented candles, soap and perfume shop",
    description: "Aromatic candles, soaps, and perfumes.",
  },
  {
    value: 48,
    label: "Sculptures, paintings and art store",
    description: "Artistic creations and sculptures.",
  },
  {
    value: 49,
    label: "Shoe store",
    description: "Stylish footwear for all occasions.",
  },
  {
    value: 50,
    label: "Skate shop",
    description: "Skateboards and skateboarding gear.",
  },
  {
    value: 51,
    label: "Souvenir shop",
    description: "Unique souvenirs and mementos.",
  },
  {
    value: 52,
    label: "Stall",
    description: "Local market stalls with diverse items.",
  },
  {
    value: 53,
    label: "Stationery shop",
    description: "Stationery supplies and office essentials.",
  },
  {
    value: 54,
    label: "Supermarket",
    description: "A variety of groceries and products.",
  },
  {
    value: 55,
    label: "Thrift store",
    description: "Second-hand and vintage items.",
  },
  {
    value: 56,
    label: "Tobacco shop",
    description: "Tobacco products and accessories.",
  },
  {
    value: 57,
    label: "Toy store",
    description: "Toys and games for all ages.",
  },
  {
    value: 58,
    label: "Vape shop",
    description: "Vaping products and supplies.",
  },
  {
    value: 59,
    label: "Warehouse store",
    description: "Shop in bulk and save at a warehouse superstore.",
  },
  {
    value: 60,
    label: "Mobile and Telecom Shop",
    description: "Discover the latest mobile devices and telecom solutions.",
  },
];
